<template>
    <section>
        <!-- Pantallas vacias -->
        <div class="row mx-0 mt-5 mb-4">
            <div class="col-auto px-0 text-general f-18 f-500">
                Pantallas vacias
            </div>
        </div>
        <!-- Pantallas vacias -->
        <div v-for="(data,index) in datos" :key="index" class="row mx-0 mb-5">
            <space-image-estados :texto="data.titulo" :tipo="data.tipo" :data="data.data" />
            <div class="col px-4 pt-4">
                <p class="f-15 text-general mt-2">{{ data.tipoLabel }}</p>
                <p class="f-15 text-general mt-2">{{ data.descripcion }}</p>
                <div class="row mx-0 mt-3">
                    <img :src="data.miniatura" width="180" height="180" />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    components: {
        spaceImageEstados: () => import('./spaceImageEstados'),
    },
    props: {
        datos:{
            type: Array,
            default: () => []
        }
    },
    data(){
        return {

        }
    }
}
</script>
<style lang="scss" scoped>
.space-image{
    width: 240px;
    height: 240px;
    border-radius: 4px;
    cursor: pointer;
    .circled-cancel{
        width: 28px;
        height: 28px;
        right: 11px;
        top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        z-index: 5;
        color: #FFFFFF;
        background-color: #7F7F7F;
    }
    img{
        border-radius: 4px;
        object-fit: cover;
    }
}
</style>
